import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
import UrlForm from '../components/UrlForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <UrlForm showLabel={true} mdxType="UrlForm" />
    <div style={{
      maxWidth: '48rem',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingLeft: '32px',
      paddingRight: '32px',
      boxSizing: 'border-box'
    }}>
      <p>{`CSS Stats provides `}
        <strong {...{
          "parentName": "p"
        }}>{`analytics and visualizations`}</strong>
        {` for your stylesheets.
This information can be used to `}
        <strong {...{
          "parentName": "p"
        }}>{`improve consistency`}</strong>
        {` in your design,
`}
        <strong {...{
          "parentName": "p"
        }}>{`track performance`}</strong>
        {` of your app, and `}
        <strong {...{
          "parentName": "p"
        }}>{`diagnose complex areas`}</strong>
        {` that might
benefit from refactoring.`}</p>
      <p><a {...{
          "href": "./about",
          "parentName": "p"
        }}>{`Learn more `}
          {`→`}</a></p>
    </div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      